// refreshSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  refreshCount: 0, // State to track refresh count
};

const refreshSlice = createSlice({
  name: 'refresh',
  initialState,
  reducers: {
    incrementRefresh: (state) => {
      state.refreshCount += 1; // Increment the refresh count
    },
    resetRefresh: (state) => {
      state.refreshCount = 0; // Reset the refresh count if needed
    },
  },
});

export const { incrementRefresh, resetRefresh } = refreshSlice.actions;
export default refreshSlice.reducer;
