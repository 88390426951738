import React, { useState, useEffect } from "react";
import applied from "../Images/iconapplied.png";
import Accepted from "../Images/iconaccepted.png";
import rejected from "../Images/iconrejected.png";
import pending from "../Images/iconpending.png";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";


const AnalyticsYQM = () => {
  const [yqmModeList, setYqmModeList] = useState("");
  const [expensebillYQMdata, setexpensebillYQMdata] = useState("");
  const [mode, setMode] = useState("");
  const [selectedYQM, setSelectedYQM] = useState("");
  const [userVoucherAnalyticsData, setUserVoucherAnalyticsData] = useState("");

  const userData = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  console.log("YQM", expensebillYQMdata);



  const handleGetUserAnalysis = async () => {
    const url = `https://expenseapi.sarahtech.com/api/Accounts/get_expense_voucher_analysis_combined`;
    try {
      const response = await axios.get(url);
      setUserVoucherAnalyticsData(response.data);
      console.log('User Analysis Data', response.data);
    } catch (error) {
      console.error('Error fetching user analysis data:', error);
      // Display a user-friendly error message or handle the error appropriately
    }
  };

  useEffect(() => {
    handleGetUserAnalysis();
  }, []);

  const handleGetModesYQMwiseData = async (mode) => {
    try {
      setMode(mode);
      const url = `https://expenseapi.sarahtech.com/api/Admin/get_list/${parseInt(mode)}`;
      const response = await axios.get(url);
      console.log("Users status voucher List", response.data);
      setYqmModeList(response?.data?.yqm);
    } catch (error) {
      console.error("Error fetching yqmModeList data:", error.message);
    }
  };

  const getYQMexpensebilldata = async (selectedYQM) => {
    try {
      setSelectedYQM(selectedYQM);
      const url = `https://expenseapi.sarahtech.com/api/Admin/get_summary_YQM/${mode}/${selectedYQM}`;
      const response = await axios.get(url);
      console.log("YQM expense bill data List", response.data);
      setexpensebillYQMdata(response.data);
    } catch (error) {
      console.error("Error fetching YQM expense bill data:", error.message);
    }
  };

  return (
    <div className="px-2 h-72">








      
<div className="flex my-5 justify-start">
  <div className="md:w-3/4 px-1">
    <div className="flex flex-col md:flex-row justify-start items-start">
      
      {/* Year Selector */}
      <div className="md:flex md:items-start mb-3 w-full mx-1">
        <div className="md:w-3/4">
          <label
            htmlFor="Year"
            className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0"
          >
           
          </label>
          <select
            id="modes"
            name="modes"
            value={mode}
            onChange={(e) => handleGetModesYQMwiseData(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
          >
            <option value="">Filter by</option>
            <option value="1">Yearly</option>
            <option value="2">Quarterly</option>
            <option value="3">Monthly</option>
          </select>
        </div>
      </div>

      {/* Expense By Selector */}
      <div className="md:flex md:items-start mb-3 w-full mx-1">
        <div className="md:w-3/4">
          <label
            htmlFor="yqm"
            className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
          >
          
          </label>
          <select
            id="userId"
            name="userId"
            value={selectedYQM}
            onChange={(e) => getYQMexpensebilldata(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
          >
            <option value="">Select</option>
            {yqmModeList &&
              yqmModeList.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
          </select>
        </div>
      </div>

    </div>
  </div>
</div>


      <div className="md:w-full px-2 mx-auto">
  <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
    {expensebillYQMdata && Array.isArray(expensebillYQMdata) ? (
      <>
        {/* Applied Expenses */}
        <Link to="/AllAppliedAnalyticsyqm" state={{ mode: mode, selectedYQM: selectedYQM, status: ''  }} className="flex items-center space-x-2">
          <div className="w-12 h-12 flex items-center justify-center">
            <img src={applied} alt="Applied" />
          </div>
          <div>
            <h3 className="font-poppins text-xs xl:text-xl">Applied Expenses</h3>
            <h1 className="font-bold text-black text-xl">
              {expensebillYQMdata
                .filter((item) => ["Approved", "Rejected", "Pending", "Accepted"].includes(item.status))
                .reduce((acc, item) => acc + (item.totalBill || 0), 0)}
            </h1>
            <h3 className="font-poppins text-xs xl:text-xl" style={{ color: "#BF83FF" }}>
              ₹{expensebillYQMdata
                .filter((item) => ["Approved", "Rejected", "Pending", "Accepted"].includes(item.status))
                .reduce((acc, item) => acc + (item.totalAmount || 0), 0)}
            </h3>
          </div>
        </Link>

        {/* Accepted Expenses */}
        <Link to="/AllAppliedAnalyticsyqm" state={{ mode: mode, selectedYQM: selectedYQM, status: 'Accepted'  }} className="flex items-center space-x-2">
          <div className="w-8 h-8 flex items-center justify-center">
            <img src={Accepted} alt="Accepted" />
          </div>
          <div>
            <h3 className="font-poppins text-xs xl:text-xl">Accepted Expenses</h3>
            <h1 className="font-bold text-black text-xl">
              {expensebillYQMdata
                .filter((item) => ["Approved", "Accepted"].includes(item.status))
                .reduce((acc, item) => acc + (item.totalBill || 0), 0)}
            </h1>
            <h3 className="font-poppins text-xs xl:text-xl" style={{ color: "#57AC65" }}>
              ₹{expensebillYQMdata
                .filter((item) => ["Approved", "Accepted"].includes(item.status))
                .reduce((acc, item) => acc + (item.totalAmount || 0), 0)}
            </h3>
          </div>
        </Link>

        {/* Pending Expenses */}
        <Link to="/AllAppliedAnalyticsyqm" state={{ mode: mode, selectedYQM: selectedYQM, status: 'Pending'  }} className="flex items-center space-x-2">
          <div className="w-8 h-8 flex items-center justify-center">
            <img src={pending} alt="Pending" />
          </div>
          <div>
            <h3 className="font-poppins text-xs xl:text-md">Pending Expenses</h3>
            <h4 className="font-bold text-black text-xl">
              {expensebillYQMdata
                .filter((item) => item.status === "Pending")
                .reduce((acc, item) => acc + (item.totalBill || 0), 0)}
            </h4>
            <h3 className="font-poppins text-xs xl:text-xl" style={{ color: "#ED7254" }}>
              ₹{expensebillYQMdata
                .filter((item) => item.status === "Pending")
                .reduce((acc, item) => acc + (item.totalAmount || 0), 0)}
            </h3>
          </div>
        </Link>

        {/* Rejected Expenses */}
        <Link to="/AllAppliedAnalyticsyqm" state={{ mode: mode, selectedYQM: selectedYQM, status: 'Rejected'  }} className="flex items-center space-x-2">
          <div className="w-8 h-8 flex items-center justify-center">
            <img src={rejected} alt="Rejected" />
          </div>
          <div>
            <h3 className="font-poppins text-xs xl:text-md">Rejected Expenses</h3>
            <h4 className="font-bold text-black text-xl">
              {expensebillYQMdata
                .filter((item) => item.status === "Rejected")
                .reduce((acc, item) => acc + (item.totalBill || 0), 0)}
            </h4>
            <h3 className="font-poppins text-xs xl:text-xl" style={{ color: "#DC4868" }}>
              ₹{expensebillYQMdata
                .filter((item) => item.status === "Rejected")
                .reduce((acc, item) => acc + (item.totalAmount || 0), 0)}
            </h3>
          </div>
        </Link>
      </>
    ) : (
      <>
        {/* Display zeros when no data is available */}
        <Link to="/AllAppliedAnalytics" state={{ status: 'all' }} className="flex items-center space-x-2">
          <div className="w-8 h-8 flex items-center justify-center">
            <img src={applied} alt="Applied" />
          </div>
          <div>
            <h3 className="font-poppins text-xs xl:text-xl">Applied Expenses</h3>
            <h1 className="font-bold text-black text-xl">{userVoucherAnalyticsData.totalApplied}</h1>
            <h3 className="font-poppins text-xs xl:text-xl" style={{ color: "#BF83FF" }}>
              ₹{userVoucherAnalyticsData.totalAppliedAmount}
            </h3>
          </div>
        </Link>

        <Link to="/AllAppliedAnalytics" state={{ status: 'Accepted' }} className="flex items-center space-x-2">
          <div className="w-8 h-8 flex items-center justify-center">
            <img src={Accepted} alt="Accepted" />
          </div>
          <div>
            <h3 className="font-poppins text-xs xl:text-xl">Accepted Expenses</h3>
            <h1 className="font-bold text-black text-xl">
              {userVoucherAnalyticsData.totalApproved + userVoucherAnalyticsData.totalAccepted}
            </h1>
            <h3 className="font-poppins text-xs xl:text-xl" style={{ color: "#57AC65" }}>
            ₹{(userVoucherAnalyticsData.totalApprovedAmount + userVoucherAnalyticsData.totalAcceptedAmount).toFixed(2)}

            </h3>
          </div>
        </Link>

        <Link to="/AllAppliedAnalytics" state={{ status: 'Pending' }} className="flex items-center space-x-2">
          <div className="w-8 h-8 flex items-center justify-center">
            <img src={pending} alt="Pending" />
          </div>
          <div>
            <h3 className="font-poppins text-xs xl:text-md">Pending Expenses</h3>
            <h4 className="font-bold text-black text-xl">{userVoucherAnalyticsData.totalPending}</h4>
            <h3 className="font-poppins text-xs xl:text-xl" style={{ color: "#ED7254" }}>
              ₹{userVoucherAnalyticsData.totalPendingAmount}
            </h3>
          </div>
        </Link>

        <Link to="/AllAppliedAnalytics" state={{ status: 'Rejected' }} className="flex items-center space-x-2">
          <div className="w-8 h-8 flex items-center justify-center">
            <img src={rejected} alt="Rejected" />
          </div>
          <div>
            <h3 className="font-poppins text-xs xl:text-md">Rejected Expenses</h3>
            <h4 className="font-bold text-black text-xl">{userVoucherAnalyticsData.totalRejected}</h4>
            <h3 className="font-poppins text-xs xl:text-xl" style={{ color: "#DC4868" }}>
              ₹{userVoucherAnalyticsData.totalRejectedAmount}
            </h3>
          </div>
        </Link>
      </>
    )}
  </div>
</div>

    </div>



  );
};

export default AnalyticsYQM;
