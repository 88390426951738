import React, { useEffect, useState } from 'react';
import MUIDataTable from "mui-datatables";
import '../style/table.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import formatDate from '../utilities/formatDate';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTheme, Box, Button, ClickAwayListener, Paper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';

import UserVoucherFill from '../Pages/UserVoucherFill';
import VoucherPopup from './VoucherPopup';
import { useDispatch } from 'react-redux';
import { setActionIdSuccess } from '../redux-toolkit/slice/eventSlice';



const EventTable = ({ data, showStatus }) => {

    const dispatch = useDispatch();
    const [visiblePopup, setVisiblePopup] = useState(null);
    const userData = useSelector((state) => state.auth.user);
    
    const [eventTableActionId, setEventTableActionId] = useState(0);
    const [showVoucher, setShowVoucher] = useState(false);
    const [voucherData, setVoucherData] = useState({
        voucherId: null,
        eventId: null,
        expenseType: "",
        expenseSubType: "",
        expenseDate: "2024-05-23T00:00:00",
        expenseAmount: null,
        attachment: null,
        attachmentPath: "",
        description: "",
        expenseTypeId:"",
        fromPlace: "",
        toPlace: "",
        distance: 0,
        fromDate: "1900-01-01T00:00:00",
        toDate: "1900-01-01T00:00:00",
        userId: 0,
        userName: "",
        role: "",
        status: "",
        entryBy: "",
        donationType: "",
        donationFor: "",
        adminActionDate: "1900-01-01T00:00:00",
        adminRemark: "",
        accountantActionDate: "1900-01-01T00:00:00",
        accountantRemark: ""
    });

    const navigate = useNavigate();


    const handleTogglePopup = (eventId) => {
        setVisiblePopup(prevState => (prevState === eventId ? null : eventId));
    };
    const getFileNameAndType = (attachmentPath) => {
        if (!attachmentPath) {
            return { fileName: "", fileType: "" }; // Return empty strings if attachmentPath is undefined
        }
        const splitPath = attachmentPath.split("\\");
        const fileName = splitPath[splitPath.length - 1];
        const fileType = fileName.split(".").pop();
        return { fileName, fileType };
    };
    
    const handleEdit = (eventId ,expenseTypeId) => {
        debugger
        console.log(`Edit event with ID: ${eventId,expenseTypeId }`);
        navigate(`/EditVoucher`);
        setVisiblePopup(null);
    };

    // const handleDelete = async (eventId) => {
    //     try {
    //         const userId = userData.userId;
    //         await axios.delete(`https://expenseapi.sarahtech.com/api/Accounts/Event_Delete`, {
    //             data: { userId, eventId }
    //         });
    //         toast.success("Event deleted successfully!");
    //         setVisiblePopup(null);
    //     } catch (error) {
    //         console.error("Error deleting event:", error);
    //         toast.error("Failed to delete event. Please try again.");
    //     }
    // };
    const handleApplyAndNavigate = (eventId, expenseTypeId, roles, userId) => {
        debugger;
        dispatch(setActionIdSuccess(eventId));
    
        if (expenseTypeId === 16) {
            if (userData.role === 'Admin') {
                navigate(`/AdminLocalConveyanceVoucherFill`);
            } else if (userData.role === 'Accountant') {
                navigate(`/UserLocalConveyanceVoucher`);
            } else {
                navigate(`/UserLocalConveyanceVoucher`);
            }
        } else if (expenseTypeId === 2) {
            if (userData.role === 'Admin') {
                navigate(`/AdminTravelVoucherFill`);
            } else {
                navigate(`/TravelVoucherFill`);
            }
        } else if (userData.role === 'Admin') {
            navigate(`/AdminMultiVoucherFill`);
        } else if (userData.role === 'Accountant') {
            if (roles.includes('Admin')) {
                navigate('/AdminMultiVoucherByAcc', { state: { userId } });
            } else {
                navigate(`/AccMultiVoucherFill`);
            }
        } else {
            navigate(`/UserVoucherMulti`);
        }
    };
    
    
    const handleApply = (eventId, expenseTypeId, roles, userId) => {
        debugger
        setEventTableActionId(eventId);
        handleApplyAndNavigate(eventId, expenseTypeId, roles,userId);
    };
    
  
    
    useEffect(()=>{
        console.log('This is Id of Event from Event Table => ', eventTableActionId)           
    },[eventTableActionId]);


    dispatch(setActionIdSuccess(eventTableActionId));

    const handleRemove = async(eventId) => {
        try {
            const userId = userData.userId;
            await axios.delete(`https://expenseapi.sarahtech.com/api/Accounts/Voucher_Delete`, {
                data: { userId, eventId }
            });
            toast.success("Event deleted successfully!");
            setVisiblePopup(null);
        } catch (error) {
            console.error("Error deleting event:", error);
            toast.error("Failed to delete event. Please try again.");
        }
    };

    const handleDetails = async (eventId) => {
        
        try {
            const response = await axios.get(`https://expenseapi.sarahtech.com/api/Accounts/get_voucher_details_by_event_id/${eventId}`);
            const responseData = response.data;

            console.log('details', responseData)
    
            // Check if responseData is empty
            if (Object.keys(responseData).length === 0 && responseData.constructor === Object) {
                toast.info("No record found for this event.");
                return; // Exit the function if no data found
            }
    
          
    
            setVoucherData({
                voucherId: responseData.voucherId,
                expenseType: responseData.expenseType,
                expenseSubType: responseData.expenseSubType,
                expenseDate: responseData.expenseDate,
                expenseAmount: responseData.expenseAmount,
                attachment: null, // You may need to adjust this based on your application's logic
                attachmentPath: responseData.attachmentPath,
                description: responseData.description,
                
                fromPlace: responseData.fromSource,
                toPlace: responseData.toSource,
                distance: responseData.distanceTravelled,
                fromDate: responseData.fromDate,
                toDate: responseData.toDate,
                userId: responseData.userId,
                userName: "", // You may need to adjust this based on your application's logic
                role: "", // You may need to adjust this based on your application's logic
                status: responseData.status,
                entryBy: "", // You may need to adjust this based on your application's logic
                donationType: "", // You may need to adjust this based on your application's logic
                donationFor: "", // You may need to adjust this based on your application's logic
                adminActionDate: responseData.adminActionDate,
                adminRemark: responseData.adminRemark,
                accountantActionDate: responseData.accountantActionDate,
                accountantRemark: responseData.accountantRemark
            });
            setVisiblePopup(eventId);
            setShowVoucher(true); // Set showVoucher to true to display the VoucherPopup
        } catch (error) {
            console.error("Error fetching voucher details:", error);
            toast.error("Failed to fetch voucher details. Please try again.");
        }
    };
    
    
  

 

    // const handleDetails = async () => {
    //     const userId = userData.userId;
    //     const url = `https://expenseapi.sarahtech.com/api/Accounts/get_user_voucher_details_by_id/${userId}`;
    //     axios.get(url)
    //         .then((response) => {
    //             setVoucherData(response.data);
    //             setShowVoucher(true);
    //         })
    //         .catch((err) => {
    //             toast.error("Failed to Load Expense Voucher.");
    //         });
    // };

    const filteredData = showStatus ? data.filter(item => item.status !== "Open") : data.filter(item => item.status === "Open");

    const TableDatas = filteredData.map((item, index) => ({
        
        sn: index + 1,
        eventId: item.eventId,
        userId: item.userId,
        eventName: item.eventName,
        task: item.task,
        entryDate: formatDate(item.entryDate),
        expensetypeId: item.expenseTypeId,
        status: item.status,
        voucherId: item.voucherId,
        roles:item.role,
        userId:item.userId,
        userName:item.userName
    }));
    const columns = [
        {
            name: "sn",
            label: "SN",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "eventName",
            label: "Event Name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => (
                    <div style={{ textAlign: 'start' }}>
                        {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
                    </div>
                )
            }
        },
        
      
        {
            name: "voucherId",
            label: "Voucher Id",
            options: {
                display: false,
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "task",
            label: "Task",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "roles",
            label: "Role",
            options: {
                display: false,
                filter: false,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "entryDate",
            label: "Entry Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "expensetypeId",
            label: "Expense Type ID",
            options: {
                display: false,
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "expensetypeId",
            label: "Expense Type",
            options: {
              display: !showStatus, // Display only when showStatus is false (e.g., statuses like "Closed" or "Approved" are shown)
              filter: true,
              sort: true,
              customBodyRender: (value) => (
                <div style={{ textAlign: 'start' }}>
                  {value === 16 ? "Local" : value === 2 ? "Travel" : "Multi"}
                </div>
              )
            }
          },
          
        
        
        {
            name: "status",
            label: "Status",
            options: {
                display: showStatus,
                customBodyRender: (value) => (
                    <p className={`capitalize px-3 py-1 inline-block cursor-pointer text-start ${value === "Accepted" && "text-yellow-500"} ${value === "Open" && "text-blue-500"} ${value === "Approved" && "text-green-500"} ${value === "Pending" && "text-orange-500"} ${value === "Rejected" && "text-red-600"} `}>{value}</p>
                ),
                filter: true,
                sort: true,
            }
        },
        {
            name: "userId",
            label: "User Id",
            options: {
                display: false,
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "userName",
            label: "Expense By",
            options: {
                display: showStatus,

                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "eventId",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                   
                    const status = tableMeta.rowData[8];  // Adjust index based on actual row data structure
                    const expenseTypeId = tableMeta.rowData[7];
                    const voucherId = tableMeta.rowData[2];  // Ensure this index matches the correct column index for voucherId
                    const roles = tableMeta.rowData[4];
                    const userId = tableMeta.rowData[9];

                    return (
                        <div className="relative flex justify-start items-start">
                            <button
                                style={{ fontSize: 'inherit', fontFamily: 'inherit' }}
                                className='capitalize px-3 py-1 inline-block text-start cursor-pointer text-blue-500'
                                onClick={() => handleTogglePopup(value)}
                            >
                                <FontAwesomeIcon icon={faEllipsisV} />
                            </button>
                            {visiblePopup === value && (
                                <ClickAwayListener onClickAway={() => setVisiblePopup(null)}>
                                    <Paper
                                        className="absolute z-10 p-2 bg-white border rounded shadow"
                                        style={{
                                            top: 'calc(100% + 20px)', 
                                            right: 0, 
                                            transform: 'translateY(-100%)', 
                                            maxHeight: '200px', 
                                            overflowY: 'auto', 
                                            zIndex: 9999, 
                                        }}
                                    >
                                        {status === "Open" ? (
                                            <>
                                                <Button
                                                    fullWidth
                                                    variant="text"
                                                    color="primary"
                                                    onClick={() => handleApply(value, expenseTypeId, roles, userId)}
                                                >
                                                    Apply
                                                </Button>
                                                {expenseTypeId !== 2 && (
                                                  <Button
                                                  fullWidth
                                                  variant="text"
                                                  onClick={() => {
                                                    navigate('/EditEvent', { state: { eventId: value } });
                                                    
                                                  }}
                                                  className="text-yellow-500" // Tailwind CSS class for yellow text color
                                                >
                                                  Edit
                                                </Button>
                                                
                                                )}
                                                {/* <Button
                                                    fullWidth
                                                    variant="text"
                                                    color="error"
                                                    onClick={() => handleDelete(value)}
                                                >
                                                    Delete
                                                </Button> */}
                                            </>
                                        ) : (
                                            <>
                                                {/* {((expenseTypeId !== 2 && status !== "Approved") || (expenseTypeId !== 2 && status === "Accepted" && userRole === "Admin")) && (
                                                <Button
                                                    fullWidth
                                                    variant="text"
                                                    onClick={() => {
                                                        if (userRole === "Admin") {
                                                            navigate('/AdminEditVoucher', { state: { eventId: value, voucherId: voucherId } });
                                                        } else {
                                                            navigate('/EditVoucher', { state: { eventId: value, voucherId: voucherId } });
                                                        }
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                            )} */}
                                            
                                                <Button
                                                    fullWidth
                                                    variant="text"
                                                    color="primary"
                                                    onClick={() => {   navigate('/VoucherDetails', { state: { eventId: value, voucherId: voucherId } }); }}
                                                >
                                                    Details
                                                </Button>
                                            </>
                                        )}
                                    </Paper>
                                </ClickAwayListener>
                            )}
                        </div>
                    );
                }
            }
        }
    ];
    
    
    

    const options = {
        selectableRows: 'none',
        filterType: 'dropdown',
        elevation: 0,
        rowsPerPageOptions: [5, 10, 15, 25, 50],
        responsive: "standard",
        tableBodyHeight: 'auto',
        tableBodyMaxHeight: 'auto'
    };

    const theme = useTheme();
     const getMuiTheme = () => createTheme({
        typography: {
            fontFamily: "Poppins",
        },
        palette: {
            background: {
                paper: "#ffffff",
                default: "#000000",
            },
            mode: 'light'
        },
        components: {
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        backgroundColor: '#ffffff !important',
                        color: '#000000 !important', // Text color of the header
                        padding: "10px 4px",
                        borderBottom: "0px solid #ddd", // Horizontal divide
                        borderRight: "0px solid #ddd", // Vertical divide
                        textAlign: "start", // start align text
                        backgroundColor: '#ffffff !important', // Background color of the header
                        '& .MuiTableSortLabel-root': {
                            color: '#000000 !important',
                            '&.MuiTableSortLabel-active': {
                                color: '#000000 !important'
                            },
                        },
                        '& .MuiTableSortLabel-icon': {
                            color: '#000000 !important'
                        },
                        '&:hover': {
                            color: '#000000 !important', // Ensure text color is black on hover
                            backgroundColor: '#ffffff !important', // Ensure background color stays white on hover
                        },
                    },
                    body: {
                        padding: "7px 15px",
                        color: "#000000",
                        borderBottom: "2px solid #ECECEC", // Horizontal divide
                        borderRight: "0px solid #000000" // Vertical divide
                    },
                    footer: {
                        padding: "7px 15px"
                    }
                }
            }
        }
    });

    return (

        <>

        {showVoucher && <VoucherPopup data={voucherData} openPopup={true} />}  
        <div className='py-5 flex'>
            <Box width="100%">
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={TableDatas}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
            </Box>
        </div>
        </>
    );
}

export default EventTable;
