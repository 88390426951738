import React, { useState, useImperativeHandle, useEffect, useRef, forwardRef } from 'react';

const generateRandomString = (length) => {
  const charset = 'abcdefghijklmnopqrstuvwxyz';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    result += charset[randomIndex];
  }
  return result;
};

const Captcha = forwardRef((props, ref) => {
  const [captcha, setCaptcha] = useState(generateRandomString(6));
  const [userInput, setUserInput] = useState('');
  const canvasRef = useRef(null);

  useImperativeHandle(ref, () => ({
    regenerateCaptcha() {
      const newCaptcha = generateRandomString(6);
      setCaptcha(newCaptcha);
      setUserInput('');
      drawCaptcha(newCaptcha);
    },
    verifyCaptcha() {
      return userInput === captcha;
    }
  }));

  useEffect(() => {
    drawCaptcha(captcha);
  }, [captcha]);

  const drawCaptcha = (text) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    // Set canvas dimensions and clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    canvas.width = 150;
    canvas.height = 50;

    // Set font and style for captcha
    ctx.font = '30px Arial';
    ctx.fillStyle = '#000'; // text color
    ctx.fillText(text, 20, 35); // Position the text

    // Optional: Add some noise for better captcha effect
    for (let i = 0; i < 5; i++) {
      ctx.beginPath();
      ctx.moveTo(Math.random() * 150, Math.random() * 50);
      ctx.lineTo(Math.random() * 150, Math.random() * 50);
      ctx.strokeStyle = '#888'; // noise color
      ctx.stroke();
    }
  };

  const handleChange = (event) => {
    setUserInput(event.target.value);
  };

  return (
    <div>
      <p className="text-lg mb-2">Captcha:</p>
      <canvas ref={canvasRef} className="border bg-gray-100 mb-2"></canvas>
      <div className="flex items-center border-b border-gray-300">
        <input
          id="captcha"
          name="captcha"
          type="text"
          autoComplete="captcha"
          placeholder="ENTER CAPTCHA"
          value={userInput}
          onChange={handleChange}
          className="input-field focus:outline-none"
        />
        <button
          type="button"
          onClick={() => ref.current.regenerateCaptcha()}
          className="mb-4 p-2 mx-2 my-2 text-lg rounded-full text-gray hover:text-gray-800 focus:outline-none w-10 h-10 flex items-center justify-center"
        >
          &#x21bb;
        </button>
      </div>
    </div>
  );
});

export default Captcha;
