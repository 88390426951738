// reducers.js
import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../slice/authSlice'
import eventReducer from '../slice/eventSlice'
import refreshReducer from '../slice/refreshSlice'

const rootReducer = combineReducers({
  auth: authReducer,
  event: eventReducer,
  refresh: refreshReducer,
  // Add other reducers here
});

export default rootReducer;
