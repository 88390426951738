import React, { useState } from 'react';
import { TERipple, TEModal, TEModalDialog, TEModalContent, TEModalHeader, TEModalBody, TEModalFooter } from "tw-elements-react";
import { useSelector } from 'react-redux';
import axios from 'axios';
import ShowMoreText from '../utilities/ShowMoreText';
import formatDate from '../utilities/formatDate';
import getFileNameAndType from '../utilities/getFileNameAndType';
import pdfIcon from '../Images/PDF_file_icon.svg.png';
import imgIcon from '../Images/7156838.png';
import { toast } from "react-toastify";

const VoucherPopupdraft = ({ data, openPopup }) => {

    const [showVoucher, setShowVoucher] = useState(openPopup);
    const [remark, setRemark] = useState('');
    const [showImage, setShowImage] = useState(false);
  const [refreshList, setRefresheList] = useState(0)


    const handleClosePopup = () => {
        setShowVoucher(false); // Close the popup
    };




    const userData = useSelector(state => state.auth.user);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    const { draftedExpenseVoucherId, expenseType, expenseSubType, expenseDate, expenseAmount, description, draftDate, attachment, fromPlace, toPlace, distance,expenseName, fromDate, toDate, userName, role, status, entryBy, adminActionDate, adminRemark } = data;
    const { fileName, fileType } = getFileNameAndType(attachment);
    const fileUrl = `https://expenseapi.sarahtech.com/api/Files/view/${fileName}.${fileType}`;

    const handleImageClick = () => {
        setShowImage(true);
    };

    const handleCloseImage = () => {
        setShowImage(false);
    };

    return (
        <TEModal show={showVoucher} setShow={setShowVoucher}>
            <TEModalDialog size="lg">
                <TEModalContent>
                    <TEModalHeader>
                        <h5 className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200">
                            Voucher Details
                        </h5>
                        <button
                            type="button"
                            className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            onClick={handleClosePopup}
                            aria-label="Close"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </TEModalHeader>

                    <TEModalBody>
                        {showImage ? (
                            <div className="flex justify-center items-center">
                                <img src={fileUrl} alt="Voucher Attachment" className="max-w-full max-h-full" />
                                <button onClick={handleCloseImage} className="absolute top-0 right-0 m-4 p-2 bg-gray-800 text-white rounded-full">
                                    Close
                                </button>
                            </div>
                        ) : (
                            <div className="border border-black p-3">
                                <div className="flex px-8">
                                    <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                        <p className="font-bold">Voucher Id :<span className='font-normal'> {draftedExpenseVoucherId} </span> </p>
                                    </div>
                                    <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1"></div>
                                </div>

                                <div className="flex">
                                    <div className="flex-grow p-4 dark" style={{ flexGrow: 3 }}>
                                        <div className="w-full px-4 py-2 flex items-center">
                                            <div className="w-full">
                                                <div className="flex w-full sm:w-1/2 lg:w-1/2 px-4 py-1">
                                                    <p className="font-bold">Expense Type:</p>
                                                    <span className='font-normal'>{expenseType}</span>
                                                </div>
                                                <div className="w-full px-4 py-1">
                                                    <p className="font-bold">Expense Sub Type :<span className='font-normal'> {expenseSubType}</span> </p>
                                                </div>
                                                <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                    <p className="font-bold">Amount: <span className='font-normal'> {expenseAmount}&nbsp;₹  </span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-3/4 px-4 py-2 flex items-center">
                                            <div className="w-full">
                                                <div className="w-full px-4 py-1">
                                                    <p className="font-bold">Fill Date :<span className='font-normal'>{formatDate(draftDate)}</span> </p>
                                                </div>
                                                <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                    <p className="font-bold">Status: <span className={`font-normal capitalize px-3 py-1 inline-block rounded-full cursor-pointer text-white text-center ${status === "Accepted" && "bg-yellow-500"} ${status === "Approved" && "bg-green-500"} ${status === "Pending" && "bg-orange-500"} ${status === "Rejected" && "bg-red-600"} `}> {status} </span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-3/4 px-4 py-2 flex items-center">
                                            <div className="flex w-full px-4 py-1">
                                                <ShowMoreText text={description} maxWords={10} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-grow p-4 flex items-center">
                                        {fileType === 'pdf' ? (
                                            <img src={pdfIcon} alt="PDF Icon" className="mr-4 w-12 h-12" />
                                        ) : (
                                            <img src={imgIcon} alt="Image" className="mr-4 w-12 h-12 cursor-pointer" onClick={handleImageClick} />
                                        )}
                                        <span className='text-blue'><a href={fileUrl} target="_blank" rel="noopener noreferrer">{`${'View'}.${fileType}`}</a></span>
                                    </div>
                                </div>

                                {expenseSubType === 'FUEL EXPENSE' && <div className="flex p-4">
                                    <div className="flex-grow lg:flex-grow-0 lg:w-3/4 px-4 py-2 flex items-center">
                                        <div className="flex w-full">
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">From : <span className="font-normal">{fromPlace} </span></p>
                                            </div>
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">To : <span className="font-normal">{toPlace}</span></p>
                                            </div>
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">Distance : <span className="font-normal">{distance}&nbsp;km</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {expenseSubType === 'MISCELLANEOUS EXPENSE' && (
                                <div className="flex p-4">
                                    <div className="flex-grow lg:flexpenseNameex-grow-0 lg:w-3/4 px-4 py-2 flex items-center">
                                        <div className="flex w-full">
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">Expense Name: <span className="font-normal">{expenseName}</span></p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            )}

                                {expenseSubType === 'TRAVEL EXPENSE' && <div className="flex p-4">
                                    <div className="flex-grow  lg:flex-grow-0 lg:w-3/4 px-4 py-2 flex items-center">
                                        <div className="flex w-full">
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">From Date : <span className="font-normal">{formatDate(fromDate)} </span></p>
                                            </div>
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">To Date : <span className="font-normal">{formatDate(toDate)}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                            </div>
                        )}
                    </TEModalBody>

                    <TEModalFooter>
                        <TERipple rippleColor="light">
                            <button
                                type="button"
                                className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                                onClick={handleClosePopup}
                            >
                                Close
                            </button>
                        </TERipple>
                    </TEModalFooter>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>
    );
};

export default VoucherPopupdraft;
