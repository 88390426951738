
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Sidemenu from "../component/Sidemenu";
import ExpeTempTable from "../component/ExpeTempTable";
import FormDetailsPopup from "../component/FormDetailsPopup";
import { getCurrentDate } from "../utilities/currentDateTime";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

const UserLocalConveyanceVoucher = () => {
    

  const [showModal, setShowModal] = useState(false);
  const [expenseSubType, setExpenseSubTypeFor] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [loading, setLoading] = useState(true);
  const [attachment, setAttachment] = useState(null); // State to track attachment
  const [checkboxChecked, setCheckboxChecked] = useState(false); // State to track checkbox
  const [errorMessage, setErrorMessage] = useState('');
  const [data, setData] = useState(false)
  const [refreshList, setRefresheList] = useState(0)
  const [userTempVoucherData, setUserTempVoucherData] = useState([]);
  const [eventIdVoucher, setEventIdVoucher] = useState(0);

  const [formData, setFormData] = useState({
    ExpenseType: '',
    SubType: '',
    ExpenseDate: '',
    ExpenseAmount: '',
    Attachment: null,
    attachmentPath: "",
    Description: 'ok',
    noOfPerson:'1',
    FromPlace: '',
    ToPlace: '',
    Distance: '',
    FromDate: '',
    ToDate: '',
    ApplyDate: '0000-00-00 00:00:00.000',
    UserName: '',
    Status: 'Pending',
    AttachmentCondition: '',
    AdminActionDate: '',
    AdminRemark: '',
    AccountantActionDate: '',
    AccountantRemark: '',
    UserId: null,
    FilledByUserId: null,
    Role: '',
    EntryBy: '',
    DonationFor: '',
    ExpenseName:'',

    DonationType: '',
    eventId: null
  });

  const navigate = useNavigate();

  const userData = useSelector(state => state.auth.user);
  const actionId = useSelector((state) => state.event.actionId)
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);


  console.log(actionId);
  useEffect(() => { setEventIdVoucher(actionId) }, [eventIdVoucher]);





  const fetchVehicle = async () => {
    try {
      const response = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_vehicle_mater');
      setVehicle(response.data); // Ensure you set the vehicle state correctly
      setLoading(false);
      console.log('Vehicle Data:', response.data); // Log the response data
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      setLoading(false);
    }
  };


  useEffect(() => {

    fetchVehicle();
  }, []);

  const fetchUserTempVoucherData = async () => {
    const eventId = actionId;
    
    const url = `https://expenseapi.sarahtech.com/api/Accounts/get_multiple_voucher_details_by_event_id/${eventId}`;

    try {
        const response = await axios.get(url);
        const data = response.data; // Adjust based on your actual API response
        setUserTempVoucherData(data);
        console.log('User Expense Data temp voucher', data);
    } catch (err) {
        console.log('User Expense Data Error', err.response.data);
    }
};



  const fetchEventData = async () => {
    const eventId = actionId;
    const url = `https://expenseapi.sarahtech.com/api/Events/get_event_details_by_event_id/${eventId}`;
    try {
      const response = await axios.get(url);
      console.log('evntid data', response);
  
     
  
      // Ensure data is an array and get the first event
      const data = response.data.data;
      const firstEvent = data.length > 0 ? data[0] : null;
  
      if (firstEvent) {
        // Extract and format the required fields
        const eventName = firstEvent.eventName || 'No Name provided';
        const task = firstEvent.task || 'No task provided';
        const entryDate = firstEvent.eventEntryDate ? firstEvent.eventEntryDate.split("T")[0] : null;
        const expenseTypeId = firstEvent.expenseTypeId ? firstEvent.expenseTypeId : null;
        const fromDate = firstEvent.fromDate ? firstEvent.fromDate.split("T")[0] : null;
        const toDate = firstEvent.toDate ? firstEvent.toDate.split("T")[0] : null;
  
        const trimmedData = {
          task,
          entryDate,
          eventName,
          expenseTypeId,
          fromDate,
          toDate
        };
  
        console.log('Event Data:', trimmedData);
        setData(trimmedData);
        return trimmedData;
      } else {
        console.log('No event data found');
        return null;
      }
    } catch (err) {
      toast.error("Failed to Load Expense Voucher.");
    }
  };
  


  
  const handleGetSubExpenseAlongwithExpenseType = async () => {
    try {
      const response = await axios.get('https://expenseapi.sarahtech.com/api/Accounts/get_all_expense_sub_type_along_with_expense_type');
      console.log('response of expense type along with sub expense type', response.data);
  
      // Filter response data where expense type id is 2
      const filteredData = response.data.listofExpenseAndSubExpense.filter(item => item.expenseId === 16);
  
      setExpenseSubTypeFor(filteredData);
    } catch (error) {
      console.log('Error in handleGetSubExpenseAlongwithExpenseType:', error);
    }
  }
  
  
  useEffect(() => {
    handleGetSubExpenseAlongwithExpenseType();
  }, []);



  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setAttachment(file);
    setFormData({
      ...formData,
      AttachmentFile: file,
    });
    console.log('File data uploaded', file)
  }


  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setCheckboxChecked(isChecked);
    setFormData((prevData) => ({
      ...prevData,
      AttachmentCondition: isChecked ? 'Yes' : 'No'
    }));
  };


  const handleSubmit = async (e) => {
    if (!attachment && !checkboxChecked) {
      setErrorMessage('Either attach a file or check the box to confirm no attachment is required.');
      return; // Prevent form submission if validation fails
    }
    debugger
    setFormData({
      ...formData,
      UserName: userData.userName,
      UserId: userData.userId,
      FilledByUserId: userData.userId,
      Role: userData.role,
      eventId: eventIdVoucher
    });
    console.log('User Data', userData);

    e.preventDefault();
    setShowModal(true)


  };

  useEffect(() => {
    console.log('form data changed: ', formData);
  }, [formData]);




  const handleSendData = async () => {


    try {

      console.log('Sending User Data', formData)
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString();

      const response = await axios.post(
        'https://expenseapi.sarahtech.com/api/Accounts/add_expense_voucher_temp',
        {
          ...formData,
          ApplyDate: formattedDate,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('Response:', response.data);
      toast.success(response.data.status);
      setFormData({
        ExpenseType: '',
        SubType: '',
        ExpenseDate: '',
        ExpenseAmount: '',
        Attachment: null,
        Description: '',
        FromPlace: '',
        ToPlace: '',
        Distance: '',
        FromDate: '',
        ToDate: '',
        ApplyDate: '0000-00-00 00:00:00.000',
        UserName: '',
        Status: 'Pending',
        AttachmentCondition: '',
        AdminActionDate: '',
        AdminRemark: '',
        AccountantActionDate: '',
        AccountantRemark: '',
        UserId: null,
        FilledByUserId: null,

        Role: '',
        EntryBy: '',
        DonationFor: '',
        DonationType: '',
        eventId: null


      });
      document.getElementById('document').value = '';
      setRefresheList(refreshList + 1);
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {

        toast.error(error.response.data);
      } else if (error.request) {

        toast.error('No response received from the server');
      } else {

        toast.error('An error occurred while processing your request');
      }
    }
  }

  
  const FinalSubmit = async () => {
    try {
        console.log('Sending User final Data', formData);
     

        // Simplified payload with only userId and eventId
        const payload = {
          
            userId: userData.userId, // Assuming formData contains UserId
            eventId: formData.eventId // Assuming formData contains eventId
        };

        const response = await axios.post(
            'https://expenseapi.sarahtech.com/api/Accounts/final_submit_expense',
            payload,
            {
                headers: {
                    'Content-Type': 'application/json', // Changed to JSON as it's a simple payload
                },
            }
        );
        console.log('Response final submit:', response.data);
        toast.success(response.data.status);
        if (userData.role === 'User') {
          navigate('/Dashboard');
        }  else if (userData.role === 'Accountant') {
          navigate('/DashboardAccount');
        }


        // Reset formData if necessary
        setFormData({
          ExpenseType: '',
          SubType: '',
          ExpenseDate: '',
          ExpenseAmount: '',
          Attachment: null,
          Description: '',
          FromPlace: '',
          ToPlace: '',
          Distance: '',
          FromDate: '',
          ToDate: '',
          ApplyDate: '0000-00-00 00:00:00.000',
          UserName: '',
          Status: 'Pending',
          AttachmentCondition: '',
          AdminActionDate: '',
          AdminRemark: '',
          AccountantActionDate: '',
          AccountantRemark: '',
          UserId: null,
          FilledByUserId: null,
          Role: '',
          EntryBy: '',
          DonationFor: '',
          DonationType: '',
          eventId: null
      });
        document.getElementById('document').value = '';
    } catch (error) {
        console.error('Error:', error);
        if (error.response) {
            toast.error(error.response.data);
        } else if (error.request) {
            toast.error('No response received from the server');
        } else {
            toast.error('An error occurred while processing your request');
        }
    }
};



  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      eventId: eventIdVoucher
    }));

    console.log('User Voucher Fill EventIdVoucher', eventIdVoucher)
  }, [eventIdVoucher, refreshList]);


  const handleChange = (e) => {
    debugger
    debugger
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));

  
}


  useEffect(() => {
    // fetchData();
     fetchUserTempVoucherData();
  }, [refreshList]);
 useEffect(() => {
    console.log('store data of User', userData);
    console.log('store data of User', isLoggedIn);
    fetchEventData();
  }, [refreshList]);


  useEffect(() => {
    console.log('Data:', data);
  }, [data]);

return (
    <div className="h-screen flex bg-[#F2FAFF]">
      <div className="p-2">
        <Sidemenu />
      </div>
      <div className="w-full flex flex-col overflow-y-auto">
        <div className="w-full h-12 flex items-center justify-start mt-12">
          <a href="/Dashboard" className="flex items-center space-x-2">
            <BiSolidLeftArrowAlt />
          </a>
          <p className="text-gray-800 font-poppins ml-2">{data.eventName
            ? data.eventName
            : "No task provided"}</p>
        </div>
        <div className="flex flex-row bg-white mx-2 py-4">
          <div className="flex w-1/3 mx-2 items-center justify-center"> {/* Updated line */}
            {/* Content on the left side */}
            <h1 className="text-xl font-bold mb-1 mx-2">Event Description:</h1>
            <p className="text-xs text-slate-400 font-poppins mx-2">{data.task ? data.task : "No task provided"}</p>
          </div>
          <div className="flex w-1/3 mx-2 items-center justify-center"> {/* Updated line */}
            <h1 className="text-xl font-bold mx-2">ApplyDate:</h1>
            <p className="text-xs text-slate-400 font-poppins mx-2">{data.entryDate ? data.entryDate : "No date provided"}</p>
          </div>
       
        </div>



        <div className="flex flex-col bg-white mx-2 my-2 items-center justify-center h-screen">
        <div className="flex flex-col md:flex-row 2xl:gap-36 bg-white mx-2 my-2">
          {/* Left section */}
          <div className="w-full md:max-w-sm max-w-64 2xl:gap-8 md:mt-20 md:mx-8 mx-8 my-2">

           
<div className="md:flex md:items-center 3xl:mb-6 mb-3">
                <div className="md:w-1/3">
                  <label
                    htmlFor="Sub Type"
                    className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Expense Type:
                  </label>
                </div>
                <div className="md:w-2/3">
                  <select
                    id="SubType"
                    name="SubType"
                    value={formData.SubType}
                    onChange={(e) => {
                      const selectedSubType = e.target.value;
                      const selectedSubTypeData = expenseSubType.find(item => item.expenseSubType === selectedSubType);
                      const selectedExpenseType = selectedSubTypeData ? selectedSubTypeData.expenseType : '';

                      handleChange(e); // Call your handleChange function with the event
                      setFormData({
                        ...formData,
                        SubType: selectedSubType, // Update SubType in formData
                        ExpenseType: selectedExpenseType // Update ExpenseType in formData
                      });
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                  >
                    <option disabled value="">
                      Select
                    </option>
                    {expenseSubType.map((item) => (
                      <option key={item.expenseSubTypeId} value={item.expenseSubType}>
                        {item.expenseSubType} - {item.expenseType}
                      </option>
                    ))}
                  </select>

                </div>
              </div>

              <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-1/3">
                    <label
                                                htmlFor="Vehicle Type"
                                                className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                Vehicle Type:
                                            </label>
                    </div>
                    <div className="md:w-2/3">
                      {/* <select
                        id="vehicleType"
                        name="vehicleType"
                        value={formData.vehicleType}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      >
                        <option disabled value="">Select</option>
                        {vehicle.map((item) => (
  <option key={item.vehicleId} value={item.vehicleType}>
    {item.vehicleType} - {item.vehicleFarePerKm} per km
  </option>
))}
                      </select> */}
                      <select
  name="vehicleType"
  onChange={(e) => {
    const [vehicleType, vehicleFarePerKm] = e.target.value.split('|'); // Split the value to get type and fare
    setFormData((prevState) => ({
      ...prevState,
      vehicleType,
      vehicleFarePerKm: parseFloat(vehicleFarePerKm), // Set the fare per km as a number
    }));
  }}
  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
>
  <option value="">Select Vehicle</option>
  {vehicle.map((item) => (
    <option key={item.vehicleId} value={`${item.vehicleType}|${item.vehicleFarePerKm}`}>
      {item.vehicleType} - {item.vehicleFarePerKm} per km
    </option>
  ))}
</select>

                    </div>
                  </div>
              


           
      
 
                                <>

<div className="md:flex md:items-center mb-3 2xl:mb-6 ">
<div className="md:w-1/3">
    <label
        htmlFor=" From Source"
        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
    >
        From Source:
    </label>
</div>
<div className="md:w-2/3">
    <input
        type="text"
        placeholder="From Place"
        name="fromSource"
        value={formData.fromSource}
        onChange={handleChange}
        required
        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
    />
</div>
</div>

                                  

<div className="md:flex md:items-center 3xl:mb-6 mb-3">
  <div className="md:w-1/3">
    <label
      htmlFor="Expense Amount"
      className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
    >
      Expense Amount:
    </label>
  </div>
  <div className="md:w-2/3">
    <input
      type="tel"
      placeholder="Expense Amount"
      name="ExpenseAmount"
      value={formData.ExpenseAmount}   // Use the calculated amount
      readOnly                        // Make this field non-editable
      className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
    />
  </div>
</div>
                                </>

                        
              <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3">
                  <label
                    htmlFor="Description"
                    className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Description:
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    placeholder="Description..."
                    name="Description"
                    value={formData.Description}
                    onChange={handleChange}
                    className="border border-gray-300 rounded-lg px-4 py-4 mr-2 w-full"
                  />
                </div>
              </div>
            </div>

            {/* right section */}
            <div className="w-full md:max-w-sm max-w-64  md:mt-20 md:mx-8 mx-8 my-2">


           


              <div className="md:flex md:items-center mb-3 2xl:mb-6">
                <div className="md:w-1/3">
                  <label
                    htmlFor="Expense Date"
                    className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Expense Date:
                  </label>
                </div>

                <div className="md:w-2/3">
                  <input
                    type="date"
                    placeholder="Expense Date"
                    name="ExpenseDate"
                    value={formData.ExpenseDate}
                    onChange={handleChange}
                    required
                    max={getCurrentDate()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                  />
                </div>
              </div>

              <div className="md:flex md:items-center 3xl:mb-6 mb-3">
  <div className="md:w-1/3">
    <label
      htmlFor="Distance"
      className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
    >
      Distance:
    </label>
  </div>
  <div className="md:w-2/3">
    <input
      type="tel"
      placeholder="Distance in KMS."
      name="distanceTravelled"
      value={formData.distanceTravelled}
      onChange={(e) => {
        const distance = e.target.value;
        const fare = formData.vehicleFarePerKm || 0; // Ensure fare is available
        const calculatedAmount = distance * fare;    // Calculate the expense amount
        setFormData({
          ...formData,
          distanceTravelled: distance,
          ExpenseAmount: calculatedAmount,           // Set calculated amount
        });
      }}
      required
      className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
    />
  </div>
</div>
<div className="md:flex md:items-center mb-3 2xl:mb-6 ">
          <div className="md:w-1/3">
              <label
                  htmlFor="To Source"
                  className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
              >
                  ToSource :
              </label>
          </div>
          <div className="md:w-2/3">
              <input
                  type="text"
                  placeholder="Destination Place"
                  name="toSource"
                  value={formData.toSource}
                  onChange={handleChange}
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
              />
          </div>
      </div>

              <div className="md:flex md:items-center mb-3 2xl:mb-8">
<div className="md:w-1/3">
  <label
    htmlFor="Attachment"
    className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
  >
    Attachment:
  </label>
</div>
<div className="md:w-2/3 flex">
  <input
    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    id="document"
    type="file"
    accept=".pdf,.doc,.docx"
    onChange={handleFileChange}
  />
</div>
</div>

{!attachment && (
<div className="md:flex md:items-center mb-3">
  <div className="md:w-1/3"></div>
  <div className="md:w-2/3">
    <label className="block text-gray-700 text-sm font-poppins mb-1">
      <input
        type="checkbox"
        checked={checkboxChecked}
        onChange={handleCheckboxChange}
      />
      I confirm that no attachment is needed.
    </label>
  </div>
</div>
)}

{errorMessage && (
<div className="text-red-500 mb-3 text-sm">
  {errorMessage}
</div>
)}

       


  <>
     

    
 
  </>



          
         





           





            </div>


          </div>
          <div className="flex justify-center">
            <div className="my-2">
              <button
                onClick={handleSubmit}
                className="bg-[#000000] text-white px-4 py-2 rounded-lg mt-0 w-full"
              >
                Submit
              </button>
            </div>





            {/* Modal component */}
            <FormDetailsPopup formData={formData} showModal={showModal} setShowModal={setShowModal} handleSendData={handleSendData} />







          </div>
        </div>


        <ExpeTempTable data={userTempVoucherData} />
        <div className="flex justify-center">
        <div className="my-2">
        <button className="bg-[#54BCE9] text-white px-4 py-2 rounded-xl mt-0 1/3"onClick={FinalSubmit}>Final Submit</button>


      </div>
      </div>
      </div>
    </div>

  )
}

export default UserLocalConveyanceVoucher