import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import axios from "axios";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Sidemenu from "../component/Sidemenu";
import Table from "../component/Table";
import { useLocation } from 'react-router-dom';
import Frame from "../Images/Frame.png";
import totalusers from "../Images/totalusers.png";
import { getCurrentDate } from "../utilities/currentDateTime";

import { useSelector } from 'react-redux';
import AnalyticsYQM from "../component/AnalyticsYQM";








const AllAnalytics = () => {


    const location = useLocation();
    const userId = location.state?.userId;
    console.log('USer ID:', userId);

    const [dataUserVoucher, setDataUserVoucher] = useState([]);
    const [dataUserVoucherFilter, setDataUserVoucherFilter] = useState([]);
  const [refreshList, setRefreshList] = useState(0);
  const [company, setCompany] = useState([]);

  const [userVoucherAnalyticsData, setUserVoucherAnalyticsData] = useState("");
  const [status, setStatus] = useState("Approved");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [companyId, setCompanyId] = useState("");                                                           

    const userData = useSelector(state => state.auth.user);


    const fetchDataUserVoucherFilter = async () => {
      const url = `https://expenseapi.sarahtech.com/api/Accounts/get_by_status_company_daterange?Status=${status}&StartDate=${startDate}&EndDate=${endDate}&CompanyId=${companyId}`;
      try {
          const response = await axios.get(url);
          setDataUserVoucherFilter(response.data);
          console.log('User Filter Data', response.data);

      } catch (err) {
          console.log('User Expense Data Error ', err.response?.data);
      }
  };

  useEffect(() => {
    if (startDate && endDate && companyId) {
      fetchDataUserVoucherFilter();
    }
}, [status, startDate, endDate, companyId]);


    const fetchCompany = async () => {
      try {
        const response = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_company_mater');
        setCompany(response.data);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    const handleGetUserAnalysis = async () => {
        const url = `https://expenseapi.sarahtech.com/api/Accounts/get_expense_voucher_analysis_combined`;
        try {
          const response = await axios.get(url);
          setUserVoucherAnalyticsData(response.data);
          console.log('User Analysis Data', response.data);
        } catch (error) {
          console.error('Error fetching user analysis data:', error);
          // Display a user-friendly error message or handle the error appropriately
        }
      };
    
      useEffect(() => {
        handleGetUserAnalysis();
            fetchCompany();

      }, []);
    


    const fetchDataUserVoucher = async () => {
   
        const url = `https://expenseapi.sarahtech.com/api/Accounts/get_user_voucher_details_by_status/${'Approved'}`;

        try {
            const response = await axios.get(url);
            const data = response.data;
            setDataUserVoucher(data);
            console.log('User Expense Data Voucher', data);
        } catch (err) {
            console.log('User Expense Data Error', err.response.data);
        }
    };








    useEffect(() => {
        fetchDataUserVoucher();
    }, []);







    const getHref = () => {
        if (userData.role === 'Admin') {
            return '/DashboardAdmin';
        } else if (userData.role === 'Accountant') {
            return '/DashboardAccount';
        }
        return '#';
    };


  return (
        <div className="h-screen flex bg-[#F2FAFF]">
            <div className="p-2">
                <Sidemenu />
            </div>
            <div className="w-full flex flex-col overflow-y-auto">
                <div className="w-full h-12 flex items-center justify-start mt-12">
                <a href={getHref()} className="flex items-center space-x-2">
                        <BiSolidLeftArrowAlt />
                    </a>
                    <h6>All Analytics</h6>

                </div>

                <div className="flex flex-row">
                <div className="Col bg-white mx-2 my-2 md:w-2/5">

                <AnalyticsYQM key={refreshList} />
                </div>

                <a href="/UserDetails" className="Col bg-white mx-2 my-2 md:w-1/5">
    <div className="flex flex-row items-start mt-8 ml-3">
        <div className="w-4 h-4 flex items-center justify-center">
            <img src={Frame} alt="Applied" />
        </div>
        <div className="ml-4 flex flex-col items-start">
            <h3 className="font-semibold text-black text-md">Total Users</h3>
            <span className="font-bold text-black text-xl mt-0"> {userVoucherAnalyticsData.totalUserscount}</span>
        </div>
    </div>

    <div className="w-36 h-36 3xl:w-44 3xl:h-44 items-center justify-center mx-6 my-16">
        <img src={totalusers} alt="Applied" />
    </div>
</a>



                </div>
                <div className="flex flex-col bg-white mx-2 my-2">
                    <div className="divide-gray-200 mx-2 w-full">
                        <div className="flex my-5 justify-start">
                            <div className="md:w-3/4 px-1">
                                <div className="flex flex-col md:flex-row justify-start items-start">
                                    <div className="md:flex md:items-start mb-3 w-full mx-1">
                                        <div className="md:w-3/4">
                                            <label
                                                htmlFor="Company"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0"
                                            >
                                                Company
                                            </label>
                                            <select
                                                id="Company"
                                                name="CompanyId"
                                                value={companyId}
                                                onChange={(e) => setCompanyId(e.target.value)}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                                            >
                                                <option value="">Select</option>
                                                {company.map((item) => (
                                                    <option key={item.companyId} value={item.companyId}>
                                                        {item.companyName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="md:flex md:items-start mb-3 w-full mx-1">
                                        <div className="md:w-3/4">
                                            <label
                                                htmlFor="StartDate"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0"
                                            >
                                                Start Date
                                            </label>
                                            <input
                                                type="date"
                                                name="startdate"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                                required
                                                max={getCurrentDate()}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                                            />
                                        </div>
                                    </div>

                                    <div className="md:flex md:items-start mb-3 w-full mx-1">
                                        <div className="md:w-3/4">
                                            <label
                                                htmlFor="EndDate"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0"
                                            >
                                                End Date
                                            </label>
                                            <input
                                                type="date"
                                                name="enddate"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                                required
                                                max={getCurrentDate()}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     
                        <Table data={dataUserVoucherFilter.length > 0 ? dataUserVoucherFilter : dataUserVoucher} />





                    </div>
                </div>
            </div>
        </div>

    )
}

export default AllAnalytics