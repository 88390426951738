import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import axios from "axios";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Sidemenu from "../component/Sidemenu";
import Table from "../component/Table";
import UserAnalytics from "../component/UserAnalytics";
import FormDetailsPopup from "../component/FormDetailsPopup";
import { getCurrentDate } from "../utilities/currentDateTime";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import formatExpenseDateInData from "../utilities/formatExpenseDateInData";
import getFileNameAndType from "../utilities/getFileNameAndType";







const AdminEditVoucher = () => {
    const location = useLocation();
    const eventId = location.state?.eventId;
    const voucherId = location.state?.voucherId;

    // Use eventId in your component logic
    console.log('Event ID:', eventId);
    console.log('Voucher ID:', voucherId);

    const [showModal, setShowModal] = useState(false);
    const [isFood, setIsFood] = useState(false)
    const [isFuel, setIsFuel] = useState(false)
    const [isTravel, setIsTravel] = useState(false)
    const [isHotel, setIsHotel] = useState(false)
    const [isParking, setIsParking] = useState(false)
    const [bank, setBank] = useState([])
  const [locations, setLocations] = useState([])

    const [paymentTypes, setPaymentTypes] = useState([])
    const [paymentMode, setPaymentMode] = useState([])
    const [company, setCompany] = useState([])
    const [data, setData] = useState(false)
    const [isMisc, setIsMisc] = useState(false)
    const [authExpenseList, setAuthExpenseList] = useState([]);
    const [userVoucherData, setUserVoucherData] = useState([]);
    const [refreshList, setRefresheList] = useState(0)
    const [expenseTypes, setExpenseTypes] = useState([]);
    const [selectedExpenseTypeId, setSelectedExpenseTypeId] = useState(0);
    const [eventIdVoucher, setEventIdVoucher] = useState(0);
    const [loading, setLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [expenseSubType, setExpenseSubTypeFor] = useState([]);
    const [showFields, setShowFields] = useState(false);
    const [isAttachmentButtonVisible, setIsAttachmentButtonVisible] = useState(false);



    const defaultValue = 0;

    const initialFormData = {
        expenseType: '',
        expenseSubType: '',
        expenseDate: '',
        expenseAmount: '',
        Attachment: null,
        attachmentPath: "",
        VoucherId: null,
        description: '',
        FromPlace: '',
        ToPlace: '',
        Distance: '',
        FromDate: '',
        ToDate: '',
        ApplyDate: '0000-00-00 00:00:00.000',
        UserName: '',
        Status: 'Pending',
        AdminActionDate: '',
        HotelName: '',
        Location: '',
        AdminRemark: '',
        AccountantActionDate: '',
        AccountantRemark: '',
        UserId: null,
        FilledByUserId: null,
        Role: '',
        EntryBy: '',
        DonationFor: '',
        DonationType: '',
        eventId: null,
        noOfPerson:'',
        paymentType:'',
    LocationId: '',

        companyName:'',
        bankName:'',
        paymentModes:''
    };

    const [formData, setFormData] = useState(initialFormData);

    const navigate = useNavigate();


    const userData = useSelector(state => state.auth.user);
    const actionId = useSelector((state) => state.event.actionId)
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);


    console.log(actionId);
    useEffect(() => { setEventIdVoucher(actionId) }, [eventIdVoucher]);

    const fetchUserVoucherData = async () => {
        setIsAttachmentButtonVisible(false);
        const url = `https://expenseapi.sarahtech.com/api/Admin/get_voucher_payment_details_by_voucher_id/${voucherId}`;

        try {
            const response = await axios.get(url);
            const data = response.data;
            setUserVoucherData(data);
            const formatedExpenseDateWithData = formatExpenseDateInData(data);
            setFormData(formatedExpenseDateWithData);
            setIsAttachmentButtonVisible(true);
            console.log('User Voucher Data payment', data);
        } catch (err) {
            console.log('User Expense Data Error', err.response.data);
        }
    };


    const fetchCompany = async () => {
        try {
            const response = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_company_mater');
            setCompany(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching companies:', error);
            setLoading(false);
        }
    };


    const fetchData = async () => {
        try {
            const response = await fetch('https://expenseapi.sarahtech.com/api/MasterApi/get_expense_type');
            if (response.ok) {
                const jsonData = await response.json()
                console.log("respo = " + response)
                console.log("jsonData = " + (jsonData))
                if (Array.isArray(jsonData) && jsonData.length > 0) {
                    console.log("Setting expenseTypes:", jsonData[0])
                    setExpenseTypes(jsonData);
                } else {
                    console.error('Data received is not an array:', jsonData);
                }
            } else {
                console.error('Failed to fetch data:', response.status);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const fetchPaymentTypes = async () => {
        try {
            const url = 'https://expenseapi.sarahtech.com/api/MasterApi/get_payment_type';
            const response = await axios.get(url);
            setPaymentMode(response.data);
            // toast.success("Payment types loaded successfully.");
        } catch (error) {
            console.error('Error fetching payment types:', error);
            toast.error("Failed to load payment types.");
        }
    };


    const fetchUserData = async () => {
        try {
            const userId = userData.userId;

            const response = await fetch(`https://expenseapi.sarahtech.com/api/Accounts/get_user_voucher_details_by_id/${userId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            // Trim time part from date strings
            const trimmedData = data.map(item => {
                return {
                    ...item,
                    expenseDate: item.expenseDate.split("T")[0], // Trim time part
                    applyDate: item.applyDate.split("T")[0] // Trim time part
                };
            });
            console.log('table Data ', trimmedData);
            setTableData(trimmedData);
            return trimmedData;
        } catch (error) {
            console.error('Error fetching data:', error);
            return []; // Return empty array in case of error
        }
    };


    const fetchEventData = async () => {
        try {
            const response = await fetch(`https://expenseapi.sarahtech.com/api/Accounts/get_voucher_details_by_event_id/${eventId}`);

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();

            // Directly use the response object as it's not an array
            const firstEvent = data;

            if (firstEvent) {
                // Extract and format the required fields
                const eventName = firstEvent.eventName || 'No Name provided';
                const task = firstEvent.task || 'No task provided';
                const entryDate = firstEvent.entryDate ? firstEvent.entryDate.split("T")[0] : null;
                const expenseTypeId = firstEvent.expenseTypeId ? firstEvent.expenseTypeId : null;

                const trimmedData = {
                    task,
                    entryDate,
                    eventName,
                    expenseTypeId,
                };

                console.log('Event Data:', trimmedData);
                setData(trimmedData);
                return trimmedData;
            } else {
                // Handle case when there is no event
                console.warn('No event found.');
                return {}; // Return empty object if no event found
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            return {}; // Return empty object in case of error
        }
    };


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({
            ...formData,
            Attachment: file,
        });
        console.log('File data uploaded', file)
    }


    const handleSubmit = async (e) => {
        debugger
        setFormData({
            ...formData,
            userName: userData.userName,
            userId: userData.userId,
            filledByUserId: userData.userId,
            role: userData.role,
            eventId: eventId,
            voucherId: voucherId
        });
        console.log('User Data', userData);

        e.preventDefault();
        setShowModal(true)


    };

    useEffect(() => {
        console.log('form data changed: ', formData);
    }, [formData]);




    const handleSendData = async () => {


        try {

            console.log('Sending User Data', formData)
            const currentDate = new Date();
            const formattedDate = currentDate.toISOString();
      const hotelDetails = `${formData.HotelName} , ${formData.Location}`;


            const response = await axios.post(
                'https://expenseapi.sarahtech.com/api/Admin/post_voucher_details_Update_by_admin',
                {
                    ...formData,
                    ApplyDate: formattedDate,
          HotelDetails: hotelDetails,

                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            console.log('Response:', response.data);
            toast.success(response.data.status);
            setFormData({
                expenseType: '',
                expenseSubType: '',
                expenseDate: '',
                expenseAmount: '',
                Attachment: null,
                attachmentPath: "",
                description: '',
                VehicleType: '',
                FromSource: '',
                ToSource: '',
                DistanceTravelled: '',
                noOfPerson: '',
                FromDate: '',
                ToDate: '',
                ApplyDate: '0000-00-00 00:00:00.000',
                AdminRemark: '',
                UserId: null,
                FilledByUserId: 'null',
                HotelDetails: '',
                AdminId: 'null',
                ExpenseName: '',
                DonationFor: '',
                DonationType: '',
                eventId: null,
                PaymentType: '',
                CompanyId: '',
    LocationId: '',

                BankId: ''
            });
            document.getElementById('document').value = '';
            setRefresheList(refreshList + 1);
        } catch (error) {
            console.error('Error:', error);
            if (error.response) {

                toast.error(error.response.data);
            } else if (error.request) {

                toast.error('No response received from the server');
            } else {

                toast.error('An error occurred while processing your request');
            }
        }
    }


    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            eventId: eventIdVoucher
        }));

        console.log('User Voucher Fill EventIdVoucher', eventIdVoucher)
    }, [eventIdVoucher]);


    const handleChange = (e) => {
        debugger
        
        console.log('handle change', e.target.value, e.target.name);
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        if (name === 'expenseType') {
            const ExpenseTypeValue =
                value === "OFFICE EXPENSE" ? 1 :
                    value === "TRAVEL EXPENSE" ? 2 :
                        value === "STAFF AND WELFARE EXPENSE" ? 3 :
                            value === "REPAIR & MAINTENANCE EXPENSE" ? 4 :
                                value === "BUSINESS & PROMOTION EXPENSE" ? 5 :
                                    value === "DONATION EXPENSE" ? 6 :
                                        value === "LEGAL & PROFESSIONAL CHARGES" ? 7 :
                                            value === "OFFICE RENT" ? 8 :
                                                value === "PRINTING & STATIONARY EXPENSE" ? 9 :
                                                    value === "ELECTRICITY EXPENSE" ? 10 :
                                                        value === "INSTALLATION EXPENSE" ? 11 :
                                                            value === "POSTAGE & COURIER EXPENSE" ? 12 :
                                                                value === "FREIGHT CHARGES" ? 13 :
                                                                    value === "VENDOR PAYMENT" ? 14 :
                                                                        defaultValue; // Default value if none of the conditions match


            const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_expense_sub_type?expenseTypeId=${ExpenseTypeValue}`;

            axios.get(url)
                .then((response) => {
                    console.log("Expense subtypes: ", response.data);
                    setExpenseSubTypeFor(response.data);
                    // Uncomment the line below if you want to show a success message to the user
                    // toast.success("Expense subtypes loaded successfully.");
                })
                .catch((err) => {
                    console.error('Response Error: ', err.response ? err.response.data : err.message);
                    toast.error("Failed to load expense subtypes.");
                });
        }


        if (name === 'expenseSubType' && value === 'FOOD EXPENSE') {
            setIsFuel(false);
            setIsTravel(false);
            setIsFood(true);
            setIsMisc(false);

            console.log('set is FOOD', isFood);
        }

        if (name === 'expenseSubType' && value === 'HOTEL EXPENSE') {
            setIsFuel(false);
            setIsMisc(false);
            setIsFood(true);
            setIsHotel(true);
        }

        if (name === 'expenseSubType' && value === 'FUEL EXPENSE') {
            setIsFuel(true);
            setIsHotel(false);
            setIsMisc(false);
            setIsFood(false);

        }

        if (name === 'expenseSubType' && value === 'PARKING') {
            setIsHotel(false);
            setIsFood(false);
            setIsFuel(false);
            setIsMisc(false);

        }

        if (name === 'expenseSubType' && value === 'TOLL') {
            setIsHotel(false);
            setIsFood(false);
            setIsFuel(false);
            setIsMisc(false);

            console.log('set is Travel', isTravel);
        }

        if (name === 'expenseSubType' && value === 'MISCELLANEOUS EXPENSE') {
            setIsHotel(false);
            setIsFood(false);
            setIsFuel(false);
            setIsMisc(true);
            console.log('set is Travel', isTravel);
        }
        if (name === 'paymentModes') {
            if (value === 'Paid') {
                setShowFields(true);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    IsPaid: true,
                }));
            } else if (value === 'Unpaid') {
                setShowFields(false);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    IsPaid: false,
                }));
            }
        }
        if (name === 'CompanyId') {
const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_bank_details_by_company_id/${value}`;
      
            axios.get(url)
              .then((response) => {
                console.log("bank: ", response.data);
                setBank(response.data);
                // Uncomment the line below if you want to show a success message to the user
                // toast.success("Expense subtypes loaded successfully.");
              })
              .catch((err) => {
                console.error('Response Error: ', err.response ? err.response.data : err.message);
                toast.error("Failed to load exbank.");
              });
      
          }
      
      
          if (name === 'CompanyId') {
      
            const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_company_location_details_by_company_id/${value}`;
      
            axios.get(url)
              .then((response) => {
                console.log("location: ", response.data);
                setLocations(response.data);
                // Uncomment the line below if you want to show a success message to the user
                // toast.success("Expense subtypes loaded successfully.");
              })
              .catch((err) => {
                console.error('Response Error: ', err.response ? err.response.data : err.message);
                toast.error("Failed to load location.");
              });
      
      
      
      
      
      
              
          }

        if (name === 'BankId') {
            debugger



            const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_payment_details_by_bank_id/${value}`;

            axios.get(url)
                .then((response) => {
                    console.log("Pay Type: ", response.data);
                    setPaymentTypes(response.data);
                    // Uncomment the line below if you want to show a success message to the user
                    // toast.success("Expense subtypes loaded successfully.");
                })
                .catch((err) => {
                    console.error('Response Error: ', err.response ? err.response.data : err.message);
                    toast.error("Failed to load expense subtypes.");
                });
        }
    };


    useEffect(() => {
    }, [isFuel]);

    useEffect(() => {
        console.log('set is Travel', isTravel);
    }, [isTravel]);


    useEffect(() => {
        fetchData();
        fetchUserVoucherData();
    }, []);





    useEffect(() => {
        console.log('store data of User', userData);
        console.log('store data of User', isLoggedIn);
        fetchEventData();
    }, [refreshList]);


    useEffect(() => {
        console.log('Data:', data);
    }, [data]);


 
    useEffect(() => {
        fetchData();
        fetchPaymentTypes();
        fetchCompany();
    }, []);




    useEffect(() => {
        console.log('store data of User', userData);
        console.log('store data of User', isLoggedIn);
        fetchUserData();
        fetchEventData();
    }, [refreshList]);


    useEffect(() => {
        if (formData.expenseType === 'OFFICE EXPENSE' && formData.expenseSubType === 'FOOD EXPENSE') {
            setIsFuel(false);
            setIsTravel(false);
            setIsFood(true);
            setIsMisc(false);

            console.log('set is FOOD', isFood);
        }

        if (formData.expenseType === 'OFFICE EXPENSE' && formData.expenseSubType === 'HOTEL EXPENSE') {
            setIsFuel(false);
            setIsMisc(false);
            setIsFood(true);
            setIsHotel(true);
        }

        if (formData.expenseType === 'OFFICE EXPENSE' && formData.expenseSubType === 'FUEL EXPENSE') {
            setIsFuel(true);
            setIsHotel(false);
            setIsMisc(false);
            setIsFood(false);

        }

        if (formData.expenseType === 'OFFICE EXPENSE' && formData.expenseSubType === 'PARKING') {
            setIsHotel(false);
            setIsFood(false);
            setIsFuel(false);
            setIsMisc(false);

        }

        if (formData.expenseType === 'OFFICE EXPENSE' && formData.expenseSubType === 'TOLL') {
            setIsHotel(false);
            setIsFood(false);
            setIsFuel(false);
            setIsMisc(false);

            console.log('set is Travel', isTravel);
        }

        if (formData.expenseType === 'OFFICE EXPENSE' && formData.expenseSubType === 'MISCELLANEOUS EXPENSE') {
            setIsHotel(false);
            setIsFood(false);
            setIsFuel(false);
            setIsMisc(true);
            console.log('set is Travel', isTravel);
        }

      

        if (formData.paymentModes === 'Paid') {
            setShowFields(true);
          } else if (formData.paymentModes === 'Unpaid') {
            setShowFields(false);
          }




        
        console.log('form data changed: ', formData);
    }, [formData]);

    return (
        <div className="h-screen flex bg-[#F2FAFF]">
            <div className="p-2">
                <Sidemenu />
            </div>
            <div className="w-full flex flex-col overflow-y-auto">
                <div className="w-full h-12 flex items-center justify-start mt-12">
                    <a href="/DashboardAdmin" className="flex items-center space-x-2">
                        <BiSolidLeftArrowAlt />
                    </a>
                    <p className="text-gray-800 font-poppins ml-2">{data.eventName
                        ? data.eventName
                        : "No task provided"}</p>
                </div>
                <div className="flex flex-row bg-white mx-2 py-4">
                    <div className="flex w-1/3 mx-2 items-center justify-center"> {/* Updated line */}
                        {/* Content on the left side */}
                        <h1 className="text-xl font-bold mb-1 mx-2">Event Description:</h1>
                        <p className="text-xs text-slate-400 font-poppins mx-2">{data.task ? data.task : "No task provided"}</p>
                    </div>
                    <div className="flex w-1/3 mx-2 items-center justify-center"> {/* Updated line */}
                        <h1 className="text-xl font-bold mx-2">Date:</h1>
                        <p className="text-xs text-slate-400 font-poppins mx-2">{data.entryDate ? data.entryDate : "No date provided"}</p>
                    </div>
                </div>



                {/* form */}
                <div className="flex flex-col bg-white mx-2 my-2 items-center justify-center h-screen">
                    <div className="flex flex-col md:flex-row 2xl:gap-36 bg-white mx-2 my-2">
                        {/* Left section */}
                        <div className="w-full md:max-w-sm max-w-64 2xl:gap-8 md:mt-20 md:mx-8 mx-8 my-2">

                            <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                <div className="md:w-1/3">
                                    <label
                                        htmlFor="ExpenseType"
                                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                    >
                                        Expense Type
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <select
                                        id="ExpenseType"
                                        name="expenseType"
                                        value={formData.expenseType}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                    >
                                        <option disabled value="">Select</option>
                                        {expenseTypes.map((item) => (
                                            <option key={item.expenseId} value={item.ExpenseName}>
                                                {item.ExpenseName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>





                            <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                                <div className="md:w-1/3">
                                    <label
                                        htmlFor="Expense Amount"
                                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                    >
                                        Expense Amount:
                                    </label>
                                </div>

                                <div className="md:w-2/3">
                                    <input
                                        type="tel"
                                        placeholder="Expense Amount"
                                        name="expenseAmount"
                                        value={formData.expenseAmount}
                                        onChange={handleChange}
                                        required
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                    />
                                </div>
                            </div>
                            {isAttachmentButtonVisible ? (<>
                                <div className="md:flex md:items-center mb-3 2xl:mb-8">
                                    <div className="md:w-1/2 px-1">
                                        <a target="blank" href={`https://expenseapi.sarahtech.com/api/Files/view/${getFileNameAndType(userVoucherData?.attachmentPath).fileName}.${getFileNameAndType(userVoucherData?.attachmentPath).fileType}`} download="adf.png">
                                            <button className="text-sm bg-info text-white px-4 py-2 rounded-lg mt-0 w-full">View Attachment</button>
                                        </a>
                                    </div>



                                    <div className="md:w-1/2 px-1">
                                        <button onClick={() => { setIsAttachmentButtonVisible(false) }} className="text-sm bg-info text-white px-4 py-2 rounded-lg mt-0 w-full">Add New </button>
                                    </div>
                                </div>

                            </>) : (<div className="md:flex md:items-center mb-3 2xl:mb-8">
                                <div className="md:w-1/3">
                                    <label
                                        htmlFor="Attachment"
                                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                    >
                                        Attachment:
                                    </label>
                                </div>
                                <div className="md:w-2/3 flex">
                                    <input
                                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        id="document"
                                        type="file"
                                        accept=".pdf,.doc,.docx"
                                        onChange={handleFileChange}

                                    />


                                </div>
                            </div>)}
                            {isFuel && (
                                <>

                                    <div className="md:flex md:items-center mb-3">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="Vehicle Type"
                                                className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                Vehicle Type:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="text"
                                                placeholder="Vehicle Type"
                                                name="Vehicle"
                                                value={formData.Vehicle}
                                                onChange={handleChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>


                                </>

                            )}
                            <div className="md:flex md:items-center mb-6">
                                <div className="md:w-1/3">
                                    <label
                                        htmlFor="Description"
                                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                    >
                                        Description:
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <input
                                        type="text"
                                        placeholder="Description..."
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                        className="border border-gray-300 rounded-lg px-4 py-4 mr-2 w-full"
                                    />
                                </div>
                            </div>
                            {isHotel && (
  <>

  <div className="md:flex md:items-center 3xl:mb-6 mb-3">
    <div className="md:w-1/3">
    <label
      htmlFor="From Date"
      className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
    >
      From Date:
    </label>
    </div>
    <div className="md:w-2/3">
    <input
      type="date"
      name="FromDate"
      value={formData.FromDate}
      onChange={handleChange}
      required
      max={getCurrentDate()} 
      className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
    />
    </div>
    </div>

  </>

)}
                        </div>

                        {/* right section */}
                        <div className="w-full md:max-w-sm max-w-64  md:mt-20 md:mx-8 mx-8 my-2">


                            <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                <div className="md:w-1/3">
                                    <label
                                        htmlFor="Sub Type"
                                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                    >
                                        Sub-Type:
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <select
                                        id=""
                                        name="expenseSubType"
                                        value={formData.expenseSubType}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                    >
                                         <option value="" disabled>{userVoucherData.expenseSubType}</option>
                                        {expenseSubType.map((item) => (
                                            <option key={item.expenseSubTypeId} value={item.expenseSubTypeName}>
                                                {item.expenseSubTypeName}
                                            </option>

                                        ))}
                                    </select>
                                </div>
                            </div>


                            <div className="md:flex md:items-center mb-3 2xl:mb-6">
                                <div className="md:w-1/3">
                                    <label
                                        htmlFor="Expense Date"
                                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                    >
                                        Expense Date:
                                    </label>
                                </div>

                                <div className="md:w-2/3">
                                    <input
                                        type="date"
                                        id="expenseDate"
                                        name="expenseDate"
                                        value={formData.expenseDate}
                                        onChange={handleChange}

                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                                    />
                                </div>

                            </div>

                            {isMisc && (
                                <>
                                    <div className="md:flex md:items-center mb-3">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="Expense Name"
                                                className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                Expense Name:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="text"
                                                placeholder="Expense Name"
                                                name="ExpenseName"
                                                value={formData.ExpenseName}
                                                onChange={handleChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>
                                </>

                            )}

{isHotel && (
  <>

    <div className="md:flex md:items-center mb-3">
            <div className="md:w-1/3">
              <label
                htmlFor="Hotel Name"
                className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
              >
                Hotel Name:
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                type="text"
                placeholder="Hotel Name"
                name="HotelName"
                value={formData.HotelName}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
              />
            </div>
          </div>
          <div className="md:flex md:items-center mb-3">
            <div className="md:w-1/3">
              <label
                htmlFor="Hotel Location"
                className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
              >
                Hotel Location:
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                type="text"
                placeholder="Location"
                name="Location"
                value={formData.Location}
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
              />
            </div>
          </div>

   

<div className="md:flex md:items-center 3xl:mb-6 mb-3">
<div className="md:w-1/3">
<label
  htmlFor="To Date"
  className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
>
  To Date:
</label>
</div>
<div className="md:w-2/3">
<input
  type="date"
  name="ToDate"
  value={formData.ToDate}
  onChange={handleChange}
  required
  max={getCurrentDate()} 
  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
/>
</div>
</div>

  </>

)}

                            {isFood && (
                                <>
                                    <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="No Of Person"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                No Of Person:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="Number"
                                                placeholder="No Of Person."
                                                name="noOfPerson"
                                                value={formData.noOfPerson}
                                                onChange={handleChange}
                                                required
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>

                                </>

                            )}

                            {isFuel && (
                                <>
                                    <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor=" From Source"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                From Source:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="text"
                                                placeholder="From Place"
                                                name="FromSource"
                                                value={formData.FromSource}
                                                onChange={handleChange}
                                                required
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>

                                    <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="To Source"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                ToSource :
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="text"
                                                placeholder="Destination Place"
                                                name="ToSource"
                                                value={formData.ToSource}
                                                onChange={handleChange}
                                                required
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>
                                    <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="Distance"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                Distance:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="tel"
                                                placeholder="Distance in KMS."
                                                name="DistanceTravelled"
                                                value={formData.DistanceTravelled}
                                                onChange={handleChange}
                                                required
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>
                                </>

                            )}





                            {/* travel */}
                            {isTravel && (
                                <>
                                    <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor=" From Source"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                From Source:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="text"
                                                placeholder="FromSource"
                                                name="FromSource"
                                                value={formData.FromSource}
                                                onChange={handleChange}
                                                required
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>

                                    <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="ToPlace"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                To :
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="text"
                                                placeholder="To Place"
                                                name="ToSource"
                                                value={formData.ToSource}
                                                onChange={handleChange}
                                                required
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>





                                    <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="From Date"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                From Date:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="date"
                                                name="FromDate"
                                                value={formData.FromDate}
                                                onChange={handleChange}
                                                required
                                                max={getCurrentDate()}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>

                                    <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="To Date"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                To Date:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="date"
                                                name="ToDate"
                                                value={formData.ToDate}
                                                onChange={handleChange}
                                                required
                                                max={getCurrentDate()}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div>


                                </>

                            )}

                            <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                <div className="md:w-1/3">
                                    <label
                                        htmlFor="paymentMode"
                                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                    >
                                        Payment Mode
                                    </label>
                                </div>
                                <div className="md:w-2/3">
                                    <select
                                        id="paymentMode"
                                        name="paymentModes"
                                        value={formData.paymentModes}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                    >
                                        <option value="">Select</option>
                                        {paymentMode.map((payment) => (
                                            <option key={payment.paymentId} value={payment.paymentName}>
                                                {payment.paymentName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {/* Payment */}

                            {showFields && (
                                <>
                                    <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="Company"
                                                className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                Company
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <select
                                                id="Company"
                                                name="CompanyId"
                                                value={formData.CompanyId}
                                                onChange={handleChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            >
                                                <option disabled value="">Select</option>
                                                {company.map((item) => (
                                                    <option key={item.companyId} value={item.companyId}>
                                                        {item.companyName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="Bank"
                                                className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                Bank
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <select
                                                id="Bank"
                                                name="BankId"
                                                value={formData.BankId}
                                                onChange={handleChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            >
                                                <option disabled value="">Select</option>
                                                {bank.map((item) => (
                                                    <option key={item.bankId} value={item.bankId}>
                                                        {item.bankName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>



                                    <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="payment Type"
                                                className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                payment Type
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <select
                                                id="PaymentTypes"
                                                name="PaymentType"
                                                value={formData.paymentType}
                                                onChange={handleChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            >
                                                <option disabled value="">Select</option>
                                                {paymentTypes.map((item) => (
                                                    <option key={item.paymentMasterId} value={item.paymentType}>
                                                        {item.paymentType}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="md:flex md:items-center 3xl:mb-6 mb-3">
    <div className="md:w-1/3">
      <label
        htmlFor=" CompanyLocation"
        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
      >
        Company Location
      </label>
    </div>
    <div className="md:w-2/3">
      <select
        id="LocationId"
        name="LocationId"
        value={formData.LocationId}
        onChange={handleChange}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
      >
        <option disabled value="">Select</option>
        {locations.map((item) => (
          <option key={item.locationId} value={item.locationId}>
            {item.companyLocation}
          </option>
        ))}
      </select>
    </div>
  </div>
                                </>

                            )}
                        </div>


                    </div>
                    <div className="flex justify-center">
                        <div className="my-2">
                            <button
                                onClick={handleSubmit}
                                className="bg-[#000000] text-white px-4 py-2 rounded-lg mt-0 w-full"
                            >
                                Submit
                            </button>
                        </div>





                        {/* Modal component */}
                        <FormDetailsPopup formData={formData} showModal={showModal} setShowModal={setShowModal} handleSendData={handleSendData} />







                    </div>
                </div>



            </div>
        </div>

    )
}

export default AdminEditVoucher