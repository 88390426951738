import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import '../style/table.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import VoucherPopup from './VoucherPopup1';
import VoucherPopupbatchApproved from './VoucherPopupbatchApproved';
import formatAmount from '../utilities/formatAmount';
import formatDate from '../utilities/formatDate';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Button, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { incrementRefresh } from '../redux-toolkit/slice/refreshSlice';
import { useDispatch } from 'react-redux';

const Tablebatch = ({ data }) => {
    const [showVoucher, setShowVoucher] = useState(false);
    const [showVoucher1, setShowVoucher1] = useState(false);
    const [selectedVouchers, setSelectedVouchers] = useState([]);  // Track selected vouchers
    const [totalAmount, setTotalAmount] = useState(0); // Track the total amount
    const [voucherData, setVoucherData] = useState({});
    const dispatch = useDispatch()
    


    const navigate = useNavigate();


    const handleGetVoucherData = async (voucherId) => {
        const url = `https://expenseapi.sarahtech.com/api/Accounts/get_voucher_details_by_voucher_id/${voucherId}`;
        try {
            const response = await axios.get(url);
            setVoucherData(response.data);
            setShowVoucher(true);
        } catch (err) {
            toast.error("Failed to Load Expense Voucher.");
        }
    };

    const handleSelectVoucher = (voucherId, amount, userId, eventId, userName) => {
        setSelectedVouchers(prevSelected => {
            const isSelected = prevSelected.some(voucher => voucher.voucherId === voucherId);
    
            if (isSelected) {
                setTotalAmount(prevTotal => prevTotal - amount);
                return prevSelected.filter(voucher => voucher.voucherId !== voucherId);
            } else {
                setTotalAmount(prevTotal => prevTotal + amount);
                return [...prevSelected, { voucherId, userId, eventId,userName }];
            }
        });
    };
    
    // const handlePreview = () => {
    //     if (selectedVouchers.length > 0) {
    //         setShowVoucher1(true);
    //         console.log('Selected Vouchers:', selectedVouchers); // Log selected vouchers
            
    //         toast.info(`Selected Vouchers: ${selectedVouchers.join(', ')}`);
    //     } else {
    //         toast.error("No vouchers selected.");
    //     }
    // };
    
    const handlePreview = () => {
        
        if (selectedVouchers.length > 1) {
            
            setShowVoucher1(false); // Close if already open, to reset the state
            setTimeout(() => setShowVoucher1(true), 0); // Re-open popup immediately
            console.log('Selected Vouchers:', selectedVouchers);
            toast.info(`Selected Vouchers: ${selectedVouchers.map(v => v.voucherId).join(', ')}`);
            
        } else {
            toast.error("No vouchers.");
        }
    };
    
    
    

    const TableData = data?.map((item, index) => ({
        ...item,
        sn: index + 1,
    }));

    const columns = [
        {
            name: "sn",
            label: "SN",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'center' }}>{value}</div>
            }
        },
        {
            name: "subType",
            label: "Expense Type",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "expenseAmount",
            label: "Amount",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => (
                    <div style={{ textAlign: 'start' }}>
                        {formatAmount(value)} &nbsp;₹
                    </div>
                )
            }
        },
        {
            name: "expenseDate",
            label: "Expense Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{formatDate(value)}</div>
            }
        },
        {
            name: "userName",
            label: "Expense By",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "adminName",
            label: "Approved By",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => (
                    <div style={{ textAlign: 'start' }}>
                        {value ? value : "Not Approved"}
                    </div>
                )
            }
        },

        {
            name: "userId",
            label: "User Id",
            options: {
                display: false,
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{formatDate(value)}</div>
            }
        },


        {
            name: "eventId",
            label: "Event Id",
            options: {
                display: false,
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{formatDate(value)}</div>
            }
        },
        {
            name: "voucherId",
            label: "Action",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    const amount = tableMeta.rowData[2]; 
                    const userName = tableMeta.rowData[4]; 
                    const userId = tableMeta.rowData[6]; 
                    const eventId = tableMeta.rowData[7]; 
        
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Checkbox
                                checked={selectedVouchers.some(voucher => voucher.voucherId === value)}
                                onChange={() => handleSelectVoucher(value, amount, userId, eventId, userName)}
                                color="primary"
                            />
                            <button
                                style={{ fontSize: 'inherit', fontFamily: 'inherit' }}
                                className='capitalize px-5 py-1 inline-block text-start cursor-pointer text-blue-500'
                                onClick={() => { handleGetVoucherData(value); setShowVoucher(!showVoucher); }}
                            >
                                View
                            </button>
                        </div>
                    );
                }
            }
        }
        
    ];

    const options = {
        selectableRows: 'none',
        filterType: 'dropdown',
        elevation: 0,
        rowsPerPageOptions: [10, 15, 25, 50],
        responsive: "standard",
        tableBodyHeight: 'auto',
        tableBodyMaxHeight: 'auto'
    };

    const getMuiTheme = () => createTheme({
        typography: {
            fontFamily: "Poppins",
        },
        palette: {
            background: {
                paper: "#ffffff",
                default: "#ffffff",
            },
            mode: 'light'
        },
        components: {
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        backgroundColor: '#ffffff !important',
                        color: '#000000 !important', // Text color of the header
                        padding: "10px 4px",
                        borderBottom: "0px solid #ddd", // Horizontal divide
                        borderRight: "0px solid #ddd", // Vertical divide
                        textAlign: "start", // start align text
                        backgroundColor: '#ffffff !important', // Background color of the header
                        '& .MuiTableSortLabel-root': {
                            color: '#000000 !important',
                            '&.MuiTableSortLabel-active': {
                                color: '#000000 !important'
                            },
                        },
                        '& .MuiTableSortLabel-icon': {
                            color: '#000000 !important'
                        },
                        '&:hover': {
                            color: '#000000 !important', // Ensure text color is black on hover
                            backgroundColor: '#ffffff !important', // Ensure background color stays white on hover
                        },
                    },
                    body: {
                        padding: "7px 15px",
                        color: "#000000",
                        borderBottom: "2px solid #ECECEC", // Horizontal divide
                        borderRight: "0px solid #000000" // Vertical divide
                    },
                    footer: {
                        padding: "7px 15px"
                    }
                }
            }
        }
    });

    return (
        <>
            {showVoucher && <VoucherPopup data={voucherData} openPopup={true} />} 

            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <h3>Total Selected Amount: {formatAmount(totalAmount)} ₹</h3>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePreview}
                    disabled={selectedVouchers.length <= 1}


                >
                    Preview Selected Vouchers
                </Button>
            </Box>

            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title="Vouchers"
                    data={TableData}
                    columns={columns}
                    options={options}
                />
            </ThemeProvider>

            {showVoucher1 && (
    <VoucherPopupbatchApproved
        selectedVouchers={selectedVouchers}  // Pass selected vouchers to the popup
        totalAmount={totalAmount}            // Pass total amount to the popup
        open={showVoucher1}
        openPopup={true}
        onClose={() => {
            setShowVoucher1(false);
            window.location.reload();  // Refresh the page on close
        }}
    />
)}

        </>
    );
};

export default Tablebatch;
