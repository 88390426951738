// import React, { useState, useEffect } from "react";
// import "react-datepicker/dist/react-datepicker.css";
// import { BiSolidLeftArrowAlt } from "react-icons/bi";
// import Sidemenu from "../component/Sidemenu";
// import axios from "axios";
// import Tablebatch from "../component/Tablebatch";







// const ExpenseRequestAccountantbatch = () => {
   
//     const [userPendingRequestVoucher, setUserPendingRequestVoucher] = useState([]);
//     const [refreshList, setRefresheList] = useState(0)

//     const [userVoucherAnalyticsData, setUserVoucherAnalyticsData] = useState("");
  

  


  

//     const getAcceptedVoucherListData = async () => {
//         try {
//             const url = 'https://expenseapi.sarahtech.com/api/Accounts/get_user_voucher_details_by_status/Accepted';
//             const response = await axios.get(url);
//             console.log('Users Pending Request', response.data);
//             setUserPendingRequestVoucher(response.data);
//             setRefresheList(refreshList + 1);

//         } catch (error) {
//             console.error('Error fetching voucher data:', error.message);
//         }
//     };


//     useEffect(() => {
//         const handleGetExpenseAnalysis = async () => {
          
//             const url = `https://expenseapi.sarahtech.com/api/Accounts/get_expense_voucher_analysis`;

//             try {
//                 const response = await axios.get(url);
//                 setUserVoucherAnalyticsData(response.data);
//                 console.log('User Analysis Data', response.data);
//             } catch (err) {
//                 console.log('User Analysis Response Error', err.response.data);
//             }
//         };

//         handleGetExpenseAnalysis();

//     }, []);


//     useEffect(() => {
//         getAcceptedVoucherListData();

//     }, []);

 

//     return (
//         <div className="h-screen flex bg-[#F2FAFF]">
//         <div className="p-2">
//           <Sidemenu />
//         </div>
//         <div className="w-full flex flex-col overflow-y-auto">
//           <div className="w-full h-12 flex items-center justify-start mt-12">
//           <a href="/DashboardAccount" className="flex items-center space-x-2">
//                           <BiSolidLeftArrowAlt />
//                       </a>
//                       <p className="text-gray-800 font-poppins ml-2">Expense Request</p>
//           </div>
  
  
            
  
  
//                   {/* Table */}
//                   <Tablebatch data={userPendingRequestVoucher} />
  
//               </div>
//           </div>
//     );
// }

// export default ExpenseRequestAccountantbatch;





import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import Sidemenu from "../component/Sidemenu";
import axios from "axios";
import Tablebatch from "../component/Tablebatch";
import { useDispatch, useSelector } from 'react-redux';
import { incrementRefresh } from '../redux-toolkit/slice/refreshSlice'; // Import the refresh action
import { getCurrentDate } from "../utilities/currentDateTime";

const ExpenseRequestAccountantbatch = () => {
    const dispatch = useDispatch();
    const refreshCount = useSelector(state => state.refresh.refreshCount); // Get refresh count from state
    const [userPendingRequestVoucher, setUserPendingRequestVoucher] = useState([]);
    const [dataUserVoucherFilter, setDataUserVoucherFilter] = useState([]);
    const [status, setStatus] = useState("Accepted");
    const [userVoucherAnalyticsData, setUserVoucherAnalyticsData] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [company, setCompany] = useState([]);
    const [companyId, setCompanyId] = useState("");                                                           
   
        const [selectedValue, setSelectedValue] = useState("");
      
        // Handle change
        const handleSelectChange = (e) => {
          setSelectedValue(e.target.value);
      
          // Optionally, parse the selected value if you need individual fields
          const [companyId, bankId, paymentMethod] = e.target.value.split(',');
          console.log("Selected Company ID:", companyId);
          console.log("Selected Bank ID:", bankId);
          console.log("Payment Method:", paymentMethod);
        };


    const fetchCompany = async () => {
        try {
          const response = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_credit_card_payment_details');
          setCompany(response.data);
        } catch (error) {
          console.error('Error fetching companies:', error);
        }
      };
   
    const fetchDataUserVoucherFilter = async () => {
        const url = `https://expenseapi.sarahtech.com/api/Accounts/get_by_status_credit_card_daterange?Status=${status}&StartDate=${startDate}&EndDate=${endDate}&PaymentDetails=${selectedValue}`;
        try {
            const response = await axios.get(url);
            setDataUserVoucherFilter(response.data);
            console.log('User Filter Data', response.data);
  
        } catch (err) {
            console.log('User Expense Data Error ', err.response?.data);
        }
    };
  
    useEffect(() => {
      if (startDate && endDate) {
        fetchDataUserVoucherFilter();
      }
  }, [status, startDate, endDate]);

    const getAcceptedVoucherListData = async () => {
       
        
        try {
            const url = 'https://expenseapi.sarahtech.com/api/Accounts/get_user_voucher_details_by_status/Accepted';
            const response = await axios.get(url);
            console.log('Users Pending Request', response.data);
            setUserPendingRequestVoucher(response.data);
        } catch (error) {
            console.error('Error fetching voucher data:', error.message);
        }
    };

    // Fetch Expense Analysis Data
    const handleGetExpenseAnalysis = async () => {
        const url = `https://expenseapi.sarahtech.com/api/Accounts/get_expense_voucher_analysis`;
        try {
            const response = await axios.get(url);
            setUserVoucherAnalyticsData(response.data);
            console.log('User Analysis Data', response.data);
        } catch (err) {
            console.log('User Analysis Response Error', err.response?.data);
        }
    };

    // Load data on component mount or refresh
    useEffect(() => {
        getAcceptedVoucherListData();
        handleGetExpenseAnalysis();
        fetchCompany();
        
    }, [refreshCount]); // Refetch when refreshCount changes

    // Function to trigger a refresh
    const refreshVoucherList = () => {
        dispatch(incrementRefresh()); // Dispatch increment action
    };


      

    return (
        <div className="h-screen flex bg-[#F2FAFF]">
            <div className="p-2">
                <Sidemenu />
            </div>
            <div className="w-full flex flex-col overflow-y-auto">
                <div className="w-full h-12 flex items-center justify-start mt-12">
                    <a href="/DashboardAccount" className="flex items-center space-x-2">
                        <BiSolidLeftArrowAlt />
                    </a>
                    <p className="text-gray-800 font-poppins ml-2">Expense Request</p>
                </div>

                {/* Button to manually refresh vouchers */}
                {/* <button onClick={refreshVoucherList} className="btn-refresh">Refresh Vouchers</button> */}

                {/* Table */}

                <div className="flex flex-col bg-white mx-2 my-2">
                    <div className="divide-gray-200 mx-2 w-full">
                        <div className="flex my-5 justify-start">
                            <div className="md:w-3/4 px-1">
                                <div className="flex flex-col md:flex-row justify-start items-start">
                                <div className="md:flex md:items-start mb-3 w-full mx-1">
                                        <div className="md:w-3/4">
                                            <label
                                                htmlFor="Company"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0"
                                            >
                                                Company
                                            </label>
                                            <select
      id="Company"
      name="CompanyId"
      value={selectedValue}
      onChange={handleSelectChange}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
    >
      <option value="">Select</option>
      {company.map((item) => (
        <option key={`${item.companyId}-${item.bankId}`} value={`${item.companyId},${item.bankId},${item.paymentMethod.toUpperCase()}`}>
          {`${item.companyName}-${item.bankName}-${item.paymentMethod.toUpperCase()}`}
        </option>
      ))}
    </select>
                                        </div>
                                    </div>
                                    <div className="md:flex md:items-start mb-3 w-full mx-1">
                                        <div className="md:w-3/4">
                                            <label
                                                htmlFor="StartDate"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0"
                                            >
                                                Start Date
                                            </label>
                                            <input
                                                type="date"
                                                name="startdate"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                                required
                                                max={getCurrentDate()}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                                            />
                                        </div>
                                    </div>

                                    <div className="md:flex md:items-start mb-3 w-full mx-1">
                                        <div className="md:w-3/4">
                                            <label
                                                htmlFor="EndDate"
                                                className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0"
                                            >
                                                End Date
                                            </label>
                                            <input
                                                type="date"
                                                name="enddate"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                                required
                                                max={getCurrentDate()}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     
                        <Tablebatch data={dataUserVoucherFilter.length > 0 ? dataUserVoucherFilter : userPendingRequestVoucher} />



 


                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExpenseRequestAccountantbatch;
